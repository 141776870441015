<template>
  <Header title="About Us" />

  <Container v-if="sectionData" class="bg-gray-50">
    <h2 class="mt-6 md:mt-2">{{ sectionData[0].topText }}</h2>
    <p>
      {{ sectionData[0].middleText }}
      <br /><br />
      {{ sectionData[0].bottomText }}
    </p>
    <h2 class="pt-6 md:mt-2">{{ sectionData[1].topText }}</h2>
    <p class="">
      {{ sectionData[1].middleText }}
    </p>
  </Container>

  <!-- Our Team -->
  <Container v-if="sectionData && krickersTeamData && epgTeamData">
    <h2 class="text-center mb-10">{{ sectionData[2].topText }}</h2>
    <div class="grid md:grid-cols-3 gap-standard">
      <ImageCard v-for="kt in krickersTeamData" :key="kt.name" :imageUrl="formatOcImage(kt.image.paths[0])">{{ kt.name }}{{ kt.role ? ', ' + kt.role : '' }}</ImageCard>
      <!-- <ImageCard imageUrl="/img/Ron.jpg">Ron Schmuck</ImageCard>
      <ImageCard imageUrl="/img/Lisa.jpg">Lisa Elley</ImageCard> -->
    </div>
	<div class="mt-14 text-center mb-14">
		<img :src="formatOcImage(sectionData[3].image.paths[0])" class="mx-auto mb-8">
		<p>{{ sectionData[3].topText }}</p>
	</div>
    <div class="grid md:grid-cols-3 gap-standard">
      <ImageCard v-for="epgt in epgTeamData" :key="epgt.name" :imageUrl="formatOcImage(epgt.image.paths[0])">{{ epgt.name }}{{ epgt.role ? ', ' + epgt.role : '' }}</ImageCard>
      <!-- <ImageCard imageUrl="/img/Herb.jpg">Herbert Braley, CLU</ImageCard>
      <ImageCard imageUrl="/img/Thomas.jpg">Thomas O'Neill</ImageCard> -->
    </div>
	
    <!-- <div class="grid md:grid-cols-6 gap-standard mt-8">
		<div class="col-span-1"></div>
      <ImageCard class="col-span-2" imageUrl="/img/Fred.jpg">Fred Humber, CLU</ImageCard>
      <ImageCard class="col-span-2" imageUrl="/img/Lew.jpg">Lew Warke, CLU</ImageCard>
		<div class="col-span-1"></div>
	</div> -->
  </Container>
</template>

<script>
import ImageCard from "../components/ImageCard.vue";
import Container from "../components/Container.vue";
import Header from "../components/Header.vue";
import { OcContentService } from '../services/ocContentService';


export default {
  components: { Header, Container, ImageCard },
  data() {
    return {
      sectionData: null,
      krickersTeamData: null,
      epgTeamData: null,
      isLoading: null,
    }
  },
  async created() {
    this.isLoading = true;
    this.fetchOcData();
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData("About", "KrickersSectionContent");
      this.krickersTeamData = (await OcContentService.fetchPageData("About", "KrickersTeamMember")).filter(t => t.team == 0);
      this.epgTeamData = (await OcContentService.fetchPageData("About", "KrickersTeamMember")).filter(t => t.team == 1);
    },
    formatOcImage(imagePath) {
      return OcContentService.formatImage(imagePath);
    },
  }
};


</script>
